(function($) {
	'use strict';
	window.bgwebsite = {
		cache: {
			$document: $(document),
			$window: $(window)
		},
		init: function() {
			this.bindEvents();
		},
		bindEvents: function() {
			var self = this; 

			$(window).ready(function() {
				if($('body').hasClass('isdesktop')){
					self.initCursor();
				}
				
				self.initMenus();
				self.initCursor();
				self.initContact();
				self.initAnimations();
				self.initCarousels();
				self.initModals();
				$(window).resize(function() {
					self.handleResize();
				});
			});
		},
		handleResize: function() {

		},
		initModals: function() {
			
		},
		openModal: function(type, id) {
			if (type === "skill") {
				console.log('skill');
				var data_station = id;
				$.get('/static/img/techs/'+data_station+'.svg', function(skillSvgIcon){
				    $('#modal_techs .zonelogo').html(skillSvgIcon);
				    
				    //var skillImg = "static/img/techs/" + data_station + ".svg";
					var skillDesc = $('.skillstat_' + data_station).attr('data-desc');
					var skillXp = $('.skillstat_' + data_station).attr('data-xp');
					var skillCat = $('.skillstat_' + data_station).attr('data-cat');
					var modalContent_stats = "<h3><strong>"+data_station+"</strong></h3><span class='skill_category'>Catégorie <strong class='categ_" + skillCat + "'>" + skillCat + "</strong></span><span class='skill_xp'>Expérience <strong>+" + skillXp + " ans</strong></span>";
					var modalContent_txt = "<span class='skill_txt'>" + skillDesc + "</span>";
					
					$('#modal_techs .zonetechdesc_stats').html(modalContent_stats);
					$('#modal_techs .zonetechdesc_txt').html(modalContent_txt);
	
					$.magnificPopup.open({
						items: [{
							src: '#modal_techs',
							type: 'inline'
						}],
						fixedContentPos: true,
						
					});
					
					/*
					// stop the out event
				    $('#modal_techs svg').find('animate').get(1).endElement();
				    // start the in one
				    $('#modal_techs svg').find('animate').get(0).beginElement();
					*/
				    
				}, 'text');
				

				
				
			}
			if (type === "service") {
				var data_service = id;
				$.magnificPopup.open({
					items: [{
						src: '#modal_service_' + id,
						type: 'inline'
					}],
					fixedContentPos: true,
					//   mainClass: 'animated bounceIn'
					//callbacks:
				});
			}
		},
		initAnimations:function(){
			
			
			
			
			setTimeout(function() {
				$('#competences_hero').addClass('loaded');
			}, 250);
				
			var sound_bullet = new Pizzicato.Sound('/static/sounds/tap-warm.wav', function() {});
			sound_bullet.volume = 0.8;
			var sound_modal_opening = new Pizzicato.Sound('/static/sounds/roll-over.wav', function() {});
			sound_modal_opening.volume = 0.4;

			$('#competences_hero svg circle').on('mouseover', function(e) {
				sound_bullet.play();
				var elem = $(this);
				var data_station = elem.attr('data-station');
				var data_line = elem.attr('data-line');
				if ((data_station === "")) {
					data_station = "Vide";
				}
				if ((data_line === "")) {
					data_line = "Vide";
				}
				$('#tooltip_station').html(data_station).show();
				$('#competences_hero svg .lines path').css('opacity', 0.25);
				$('#competences_hero svg g#ligne_' + data_line + ' path').css('opacity', 1);
				$('#competences_hero svg .stations circle').css('opacity', 0.25);
				$('#competences_hero svg g#stations_' + data_line + ' circle').css('opacity', 1);

			})
			.on('mouseout', function() {
				$('#competences_hero svg .lines path').css('opacity', 1);
				$('#competences_hero svg .stations circle').css('opacity', 1);
				$('#tooltip_station').html('').hide();
			})
			.on('click', function() {
				
				var elem = $(this);
				var data_station = elem.attr('data-station');
				sound_modal_opening.play();
				console.log('clic '+data_station);
				window.bgwebsite.openModal('skill', data_station);
								
			});
/*
			if(!$('body').hasClass('ismobile'){
				$('h1,h2,h3').each(function(){
					if ( $(this).is( ':in-viewport' ) ) {
						$(this).addClass('seen');
					}	
		      	});
				$('.animate__animated').each(function(e){			
					if ( $(this).is( ':in-viewport' ) ) {
						$(this).addClass($(this).data('animation'));
			      	}
		      	});
		    } else {
			    $('h1,h2,h3').addClass('seen');
			    $('.animate__animated').addClass('seen');
		    }
*/


			$('.animate__animated').each(function(e){			
				if ( $(this).is( ':in-viewport' ) ) {
					$(this).addClass($(this).data('animation'));
		      	}
	      	});
	      	$('h1,h2,h3').each(function(){
				if ( $(this).is( ':in-viewport' ) ) {
					$(this).addClass('seen');
				}	
	      	});
			if(!$('body').hasClass('ismobile')){
				var scroll_target = $('#wrapper');	
			} else {
				var scroll_target = $(window);
			}
			scroll_target.on('scroll',function() {
				//alert("scroll");
					
					$(".skillData").each(function(index, element) {
						var mydata = $(element).data();
						var cnt = 0;
						if ( $(this).is( ':in-viewport' ) && !$(this).hasClass('seen') ) {
					   	 	go();
					   	 	$(this).addClass('seen');
					   	}
					    // element == this
					    
						
					    //recursive call with a time delay so user can see graph draw.
					    function go() {
					        if (cnt++ < mydata['percent']) {
					            setTimeout(go, 10);
					        }
					        $(element).css('width', cnt + '%');
					
					    }
						
					
					});
				
				
				
				$('.animate__animated').each(function(e){			
					if ( $(this).is( ':in-viewport' ) ) {
						$(this).addClass($(this).data('animation'));
			      	}
		      	});
		      	$('h1,h2,h3').each(function(){
					if ( $(this).is( ':in-viewport' ) ) {
						$(this).addClass('seen');
					}	
		      	});
		      	$('.unestat_chiffre').each(function(e){			
					if ( $(this).is( ':in-viewport' ) && !$(this).hasClass('countdone') ) {
						var countid		= $(this).data('countid');
						let homestats 	= new CountUp('chiffre_'+countid, 
						$(this).data('count'),
						{
							decimal:'',
							separator:''
						});
						if (!homestats.error) {
							homestats.start();
						} else {
						  console.error(homestats.error);
						}
						$(this).addClass('countdone');
					}
				});
			});
			
			if ($('body').hasClass('page_home')) {

				var a = function(a) {
						return document.querySelector(a)
					},
					t = document.querySelectorAll(".monitorContentGroup path");
				a(".monitorGroup");
				var f = a(".monitorScreen"),
					g = a(".monitorLogo"),
					h = a(".monitorStand"),
					u = a(".monitorStandShadow"),
					c = a(".monitorBottom"),
					v = a(".monitorEdge"),
					C = document.querySelectorAll(".laptopContentGroup path"),
					w = a(".laptopContentGroup"),
					x = a(".laptopGroup"),
					d = a(".laptopScreen"),
					k = a(".laptopEdgeLeft"),
					l = a(".laptopEdgeRight"),
					m = a(".laptopTrackpad"),
					n = a(".laptopBase");
				document.querySelectorAll(".tabletContentGroup path");
				var e = a(".tabletContentGroup"),
					b = a(".tabletGroup"),
					p = a(".tabletButton"),
					q = a(".tabletCamera"),
					y = a(".tabletScreen"),
					r = a(".phoneGroup"),
					z = a(".phoneButton"),
					A = a(".phoneCamera"),
					a = a(".phoneSpeaker");
				TweenMax.set([c], {
					transformOrigin: "50% 100%"
				});
				TweenMax.set([h, n, y], {
					transformOrigin: "50% 0%"
				});
				TweenMax.set([g, f, d, m, b, x, p, q, e, z, A, a, w, r], {
					transformOrigin: "50% 50%"
				});
				TweenMax.set([k, l], {
					transformOrigin: "0% 100%"
				});
				TweenMax.set(b, {
					rotation: -90
				});
				var B = new TimelineMax({
					repeat: -1,
					delay: 1,
					yoyo: !1,
					paused: !1
				});
				B.timeScale(3);
				B.from(c, 1, {
					scaleY: 0,
					ease: Power1.easeOut
				}).from(h, 1, {
					y: -70,
					ease: Power1.easeOut
				}, "-=1").from(u, .5, {
					alpha: 0,
					ease: Power1.easeIn
				}, "-=0.5").from(v, 1, {
					y: 330
				}, "-=0.25").from(f, 2, {
					y: 330,
					ease: Power1.easeOut
				}, "-=1").staggerFrom(t, 1, {
					scaleX: 0
				}, .1, "-=0.51").from(g, 1, {
					scale: 0,
					ease: Back.easeOut.config(2)
				}).staggerTo(t, 1, {
					alpha: 0,
					delay: 2
				}, .1).to(f, 1, {
					y: 330,
					ease: Power1.easeIn
				}, "-=1").to(v, 1, {
					y: 330,
					ease: Power1.easeIn
				}, "-=0.75").to(c, 1, {
					scaleX: .69,
					y: -23
				}).to(c, 1, {
					scaleY: .45,
					alpha: 1
				}, "-=1").set(c, {
					alpha: 0
				}).to(g,
					.5, {
						scale: 0,
						ease: Back.easeIn
					}, "-=1").to(h, 1, {
					y: -120
				}, "-=1.5").to(u, .5, {
					alpha: 0
				}, "-=1.5").from(n, 1, {
					alpha: 0
				}, "-=1").from(m, 1, {
					scaleX: 0
				}, "-=1").from(d, 1, {
					scale: 0,
					ease: Back.easeOut.config(.5)
				}).from(k, 2, {
					skewX: -40,
					scaleY: 0,
					ease: Power3.easeOut
				}, "-=2").from(l, 2, {
					skewX: 40,
					scaleY: 0,
					ease: Power3.easeOut
				}, "-=2").staggerFrom(C, 1, {
					scaleX: 0
				}, .1).to(m, .3, {
					alpha: 0,
					delay: 2
				}).to(d, 1, {
					scaleX: .67
				}).to(d, 1, {
					scaleY: .8
				}, "-=1").to(w, 1, {
					alpha: 0,
					scale: .5
				}, "-=1").to(n, 1, {
					y: -20,
					scaleX: 0
				}, "-=1").to(k, 1, {
					x: 40,
					transformOrigin: "50% 50%",
					scaleY: .85
				}, "-=1").to(l, 1, {
					x: -40,
					transformOrigin: "50% 50%",
					scaleY: .85
				}, "-=1").set(x, {
					alpha: 0
				}).from(b, 1, {
					scale: 1.1,
					alpha: 0
				}, "-=1").to(b, 2, {
					rotation: 0,
					delay: 2,
					ease: Anticipate.easeOut
				}).staggerFrom([p, q], .5, {
					scale: 0,
					ease: Back.easeOut
				}, .1, "-=1").from(e, 2, {
					rotation: 90,
					scaleX: 1.33,
					scaleY: .8,
					ease: Power3.easeInOut
				}, "-=0").to([p, q], .5, {
					scale: 0,
					delay: 2
				}).to(b, 1, {
					scaleX: .45
				}).to(b, 1, {
					scaleY: .7
				}, "-=1").to(e, 1, {
					y: -5
				}, "-=1").to(y, .5, {
					scaleY: .92,
					y: 4
				}, "-=0.5").set(r, {
					alpha: 1
				}).set([b, e], {
					alpha: 0
				}).staggerFrom([z,
					A, a
				], 1, {
					scale: 0,
					ease: Back.easeOut
				}, .1).to(r, 2, {
					y: 80,
					delay: 2,
					ease: Back.easeIn.config(2)
				});
				TweenMax.set("svg", {
					visibility: "visible"
				})
			}
			
			$("#techlist li").hover(function(event){
				console.log('hover');
			    // stop the out event
			    $(this).find('svg').find('animate').get(1).endElement();
			    // start the in one
			    $(this).find('svg').find('animate').get(0).beginElement();
			  }, function(event){
			    $(this).find('svg').find('animate').get(0).endElement();
			    $(this).find('svg').find('animate').get(1).beginElement();
			});
				
		},
		initContact: function() {
			console.log("init contact form");
			//$('#formloader').fadeOut();
			$("#contactform").validate({  
				rules: {
					"field_email": {
						required: true,
						email: true
					},
					"field_name": {
						required: true,
					},
					"field_phone": {
						required: false,
					},

					"field_message": {
						required: true,
					},
					"field_captcha":{
						required:true,
						captcha:true
					}
				},
				submitHandler: function(myform, event) {
					event.preventDefault();
					$('#formloader').fadeIn();
					
							var data = $(myform).serialize();
							//console.log(data);
							$.post("/inc/sendmail.php", data, function(data) {
								
							}).done(function(datareturn) {
								console.log(datareturn);
								$('#formloader').fadeOut();
								var thanksmessage = "<div class='alert alert-success' role='alert'>Votre message a bien été envoyé, merci et à très bientôt !</div>";
								var spammessage = "<div class='alert alert-warning' role='alert'>Spam détecté !</div>";
								var errmessage = "<div class='alert alert-error' role='alert'>Oups, une erreur est survenue ! Merci de réessayer ultérieurement.</div>";
								
								if (datareturn === "sent") {
									//$(myform).trigger('reset').slideUp();
									$('#contactform_feedback').append(thanksmessage);
								}
								else if (datareturn === "spam") {
									$('#contactform_feedback').append(spammessage);
								}
								else{
									$('#contactform_feedback').append(errmessage);
								}
								
							});
						
				}
			});
		},
		
		initCursor: function() {

			var followCursor = (
				function() {
					var s = $('#tooltip_station');
					s.hide();

					return {
						run: function(e) {
							var e = e || $('#skillmap').event;
							var x = e.pageX - $('#skillmap').offset().left;
							var y = e.pageY - $('#skillmap').offset().top;

							s.css('left', (x) + 'px');
							s.css('top', (y) + 'px');
							// getMouseCoords(e);
						}
					};
				}());
			if ($('body').hasClass('page_competences')) {
				$('#skillmap').on('mousemove', followCursor.run);
			}

			var cursor = $("#main_cursor"),
				follower = $("#main_follower");
			var posX = 0,
				posY = 0;
			var mouseX = 0,
				mouseY = 0;
			TweenMax.to({}, 0.016, {
				repeat: -1,
				onRepeat: function() {
					posX += (mouseX - posX) / 9;
					posY += (mouseY - posY) / 9;

					TweenMax.set(follower, {
						css: {
							left: posX - 12,
							top: posY - 12
						}
					});

					TweenMax.set(cursor, {
						css: {
							left: mouseX,
							top: mouseY
						}
					});
				}
			});

			$(document).on("mousemove", function(e) {
				mouseX = e.pageX;
				mouseY = e.pageY;
			});


			$("a,button,svg .stations circle,#techlist li").on("mouseenter", function() {
				cursor.addClass("active");
				follower.addClass("active");
			}).on("mouseleave", function() {
				cursor.removeClass("active");
				follower.removeClass("active");
			}); 


		},
		initMenus: function() {
			
			
			if($('body').hasClass('isdesktop') && $('body').hasClass('page_home')){
				var instance = new vidbg('#home_hero', {
				  mp4: '/static/video/home_hero.mp4', // URL or relative path to MP4 video
				  webm: '/static/video/home_hero.webm', // URL or relative path to webm video
				  poster: '/static/video/home_hero.jpg', // URL or relative path to fallback image
				  overlay: true, // Boolean to display the overlay or not
				  overlayColor: '#2f2e41', // The overlay color as a HEX
				  overlayAlpha: 0.4 // The overlay alpha. Think of this as the last integer in RGBA()
				}, {
				  // Attributes
				});
			}
			
			var sound_modal_opening = new Pizzicato.Sound('/static/sounds/roll-over.wav', function() {});
			sound_modal_opening.volume = 0.6;
			
			$('#techlist li').on('click', function() {
				var elem = $(this);
				var data_station = $(this).attr('data-icon');
				sound_modal_opening.play();
				window.bgwebsite.openModal('skill', data_station);
			});
			
		},
		initCarousels: function(){
			
			 /**************************************************************************/
			 /*************************** MORPH POLYGONS *******************************/
			/**************************************************************************/
			const links = document.querySelectorAll(".link_svgmorph");
			let toPolygonArray = [];
			let fromPolygonArray = [];

			// get points of polygons using regex
			const getCoordinates = polygon => {
				return polygon.getAttribute("points").match(/(-?[0-9][0-9\.]*),(-?[0-9][0-9\.]*)\ (-?[0-9][0-9\.]*),(-?[0-9][0-9\.]*)\ (-?[0-9][0-9\.]*),(-?[0-9][0-9\.]*)/);
			};

			const createPolygonPointsObject = polygons => {
				const polygonsArray = [];

				polygons.forEach((polygon, i) => {
					const coordinates = getCoordinates(polygon);

					polygonsArray.push({
						fill: polygon.getAttribute("fill"),
						one: coordinates[1],
						two: coordinates[2],
						three: coordinates[3],
						four: coordinates[4],
						five: coordinates[5],
						six: coordinates[6]
					});

				});

				return polygonsArray;
			};

			const animatePolygons = () => {
				const polygons = document.querySelector(".svg-holder").querySelectorAll("polygon");
				fromPolygonArray = createPolygonPointsObject(polygons);

				fromPolygonArray.forEach((obj, i) => {
					TweenMax.to(obj, 1, {
						one: toPolygonArray[i].one,
						two: toPolygonArray[i].two,
						three: toPolygonArray[i].three,
						four: toPolygonArray[i].four,
						five: toPolygonArray[i].five,
						six: toPolygonArray[i].six,
						ease: Power3.easeOut,
						onUpdate: () => {
							polygons[i].setAttribute("points", `${obj.one},${obj.two} ${obj.three},${obj.four} ${obj.five},${obj.six}`);
						}
					});

				});

				// animate color
				polygons.forEach((polygon, i) => {
					const toColor = toPolygonArray[i].fill;

					TweenLite.to(polygon, 1, {
						fill: toColor,
						ease: Power3.easeOut
					});

				});
			};

			// add points attribute values to arrays
			const updatePolygonArrays = idToAnimateTo => {
//							if ($('body').hasClass('home')) {
					toPolygonArray = createPolygonPointsObject(document.getElementById(idToAnimateTo).querySelectorAll("polygon"));

					animatePolygons();
				//}
				fromPolygonArray = toPolygonArray;
			};
			
			var testSwiper = new Swiper('#testimonial_swiper_container', {
				direction: 'horizontal',
				loop: false,
				slidesPerView: 1,
				roundLengths:true,
				autoHeight: false,
				keyboard: true,
				height: $('#testimonial_swiper_container').outerHeight(),
				simulateTouch: true,
				mousewheel: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable:true
				},

				on: {
					init: function() {
						 
					},
					slideChange: function() {
						//animateChildSwiper('#testimonial_swiper_container');
						var activeSlide = $('#testimonial_swiper_container')[0].swiper.activeIndex;
						var idToAnimateTo = $('#testimonial_swiper_container .swiper-slide').eq(activeSlide).attr("data-author");
						updatePolygonArrays(idToAnimateTo);
					}
				}
			});

			$('.rightlink').on('click', function(e) {
				console.log('right');
				testSwiper.slideNext();
				e.preventDefault();
			});
			$('.leftlink').on('click', function(e) {
				console.log('left');
				testSwiper.slidePrev();
				e.preventDefault();
			});	
			
			
			var bookSwiper = new Swiper('.book_swiper', {
				direction: 'horizontal',
				loop: false,
				
				
				slidesPerView: 1,
				roundLengths:true,
				autoHeight: false,
				keyboard: true,
				//height: $('#testimonial_swiper_container').outerHeight(),
				simulateTouch: true,
				mousewheel: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination:{
					type:'progressbar'
				},
				renderProgressbar: function (progressbarFillClass) {
					return '<span class="' + progressbarFillClass + '"></span>';
  				},
				on: {
					init: function() {
						
						
						$('.book_swiper').on('mouseenter',function(){
							if($(this)[0].swiper.animating === false){
								$(this)[0].swiper.params.autoplay.delay = 1000;
								$(this)[0].swiper.autoplay.start();
							}
						}).on('mouseleave',function(){
							
								$(this)[0].swiper.autoplay.stop();
							
							
						});
						
					},
					slideChange: function() {
					}
				}
			});
			
		},
		
	}
	bgwebsite.init();
})(jQuery);